<template>
  <Content>
    <template #contentBody>
      <a-row>
        <a-col :span="24">
          <a-steps :current="0" style="max-width: 1800px" size="small">
            <a-step :title="$t('warehouse.select_out_warehouse_way')" />
            <a-step :title="$t('warehouse.add_box_info')" />
            <a-step :title="$t('warehouse.confirm_out_plan')" />
            <a-step :title="$t('common.completed')" />
          </a-steps>
        </a-col>
      </a-row>
      <div class="mt-3">
        <a-form ref="refForm" :rules="rules" :model="data">
          <!-- 库存所在仓 -->
          <a-row>
            <a-col :span="24" :xl="{ span: 8 }" :xxl="{ span: 6 }">
              <a-form-item
                name="selectedInventoryWarehouseId"
                :label-col="{
                  sm: { span: 4 },
                  md: { span: 3 },
                  xl: { span: 6 },
                  xxl: { span: 6 },
                }"
                :wrapper-col="{
                  sm: { span: 20 },
                  md: { span: 21 },
                  xl: { span: 18 },
                  xxl: { span: 18 },
                }"
                :label="$t('warehouse.inventory_affiliation_warehouse')"
              >
                <a-select
                  v-model:value="data.selectedInventoryWarehouseId"
                  @change="handelChangeSelectInventoryWarehouse"
                  :placeholder="$t('common.please_select')"
                  :loading="inventoryWarehouseNoLoading"
                  optionFilterProp="search-key"
                  show-search
                >
                  <a-select-option
                    v-for="item in inventoryWarehouseNos"
                    :key="item.warehouseId"
                    :title="`${item.warehouseNo}(${item.warehouseName})`"
                    :search-key="item.warehouseNo + item.warehouseName"
                    :value="item.warehouseId"
                  >
                    {{ item.warehouseNo }}({{ item.warehouseName }})
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col
              v-if="data.selectedInventoryWarehouseId"
              :sm="{ span: 24 }"
              :xl="{ span: 16 }"
              :xxl="{ span: 18 }"
            >
              <a-form-item
                :label-col="{
                  sm: { span: 4 },
                  md: { span: 3 },
                  xl: { span: 3 },
                  xxl: { span: 2 },
                }"
                :label="$t('warehouse.warehouse_address')"
              >
                {{ getAddress(data.inventoryWarehouseAddress) }}
                <span v-if="data.inventoryWarehouseAddress?.linkMan"
                  >({{ getLinkman(data.inventoryWarehouseAddress) }})</span
                >
              </a-form-item>
            </a-col>
          </a-row>

          <!-- 出库类型 -->
          <a-row>
            <a-col
              :span="24"
              :md="{ span: 24 }"
              :xl="{ span: 8 }"
              :xxl="{ span: 6 }"
            >
              <a-form-item
                :label-col="{
                  sm: { span: 4 },
                  md: { span: 3 },
                  xl: { span: 6 },
                  xxl: { span: 6 },
                }"
                name="selectedOutWarehouseType"
                :label="$t('warehouse.out_warehouse_type')"
              >
                <a-select
                  v-model:value="data.selectedOutWarehouseType"
                  @change="handelChangeSelectOutType"
                  :placeholder="$t('common.please_select')"
                >
                  <a-select-option
                    v-for="(value, key, index) in outWarehouseType"
                    :key="key"
                    :value="value"
                    :data-index="index"
                  >
                    {{ $t($enumLangkey("outWarehouseType", value)) }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>

          <!-- 仓库代码 -->
          <a-row>
            <a-col :sm="{ span: 24 }" :xl="{ span: 8 }" :xxl="{ span: 6 }">
              <a-form-item
                v-if="
                  data.selectedOutWarehouseType ==
                  outWarehouseType.betweenWarehouseAllocate
                "
                name="selectTargetWarehouseNo"
                :label-col="{
                  sm: { span: 4 },
                  md: { span: 3 },
                  xl: { span: 6 },
                  xxl: { span: 6 },
                }"
                :wrapper-col="{
                  sm: { span: 20 },
                  md: { span: 21 },
                  xl: { span: 18 },
                  xxl: { span: 18 },
                }"
                :label="$t('warehouse.warehouse_code')"
              >
                <a-select
                  v-model:value="data.selectTargetWarehouseNo"
                  optionFilterProp="search-key"
                  show-search
                  @change="handelChangeSelectWarehouseCode"
                  :placeholder="$t('common.please_select')"
                >
                  <a-select-option
                    v-for="item in targetWarehouseNos"
                    :title="`${item.warehouseNo}(${item.warehouseName})`"
                    :search-key="item.warehouseNo + item.warehouseName"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.warehouseNo }}({{ item.warehouseName }})
                  </a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item
                v-if="
                  data.selectedOutWarehouseType == outWarehouseType.amazonFba ||
                  data.selectedOutWarehouseType == outWarehouseType.mercadolibre
                "
                name="selectTargetWarehouseNo"
                :label-col="{
                  sm: { span: 4 },
                  md: { span: 3 },
                  xl: { span: 6 },
                  xxl: { span: 6 },
                }"
              >
                <template #label>
                  <span
                    v-if="
                      data.selectedOutWarehouseType ==
                      outWarehouseType.amazonFba
                    "
                  >
                    {{ $t("warehouse.fba_warehouse_code") }}
                  </span>
                  <span v-else>{{ $t("warehouse.fbm_warehouse_code") }}</span>
                </template>

                <a-select
                  v-model:value="data.selectTargetWarehouseNo"
                  @change="handelChangeSelectWarehouseCode"
                  :placeholder="$t('common.please_select')"
                  show-search
                  optionFilterProp="search-key"
                >
                  <a-select-option
                    v-for="item in targetWarehouseNos"
                    :key="item.id"
                    :value="item.id"
                    :search-key="item.name"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col
              v-if="
                data.selectTargetWarehouseNo &&
                data.targetAddress &&
                data.selectedOutWarehouseType !=
                  outWarehouseType.replaceSendWarehouseEntry &&
                data.selectedOutWarehouseType != outWarehouseType.destruction
              "
              :sm="{ span: 24 }"
              :xl="{ span: 16 }"
              :xxl="{ span: 18 }"
            >
              <a-form-item
                :label="$t('warehouse.transport_goods_address')"
                :label-col="{
                  sm: { span: 4 },
                  md: { span: 3 },
                  xl: { span: 3 },
                  xxl: { span: 2 },
                }"
              >
                {{ getAddress(data.targetAddress) }}
                <template
                  v-if="
                    data.selectedOutWarehouseType ==
                    outWarehouseType.betweenWarehouseAllocate
                  "
                >
                  <span v-if="data.targetAddress?.linkMan"
                    >({{ getLinkman(data.targetAddress) }})</span
                  >
                </template>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 常用地址 -->
      <div
        v-if="data.selectedOutWarehouseType == outWarehouseType.otherAddress"
      >
        <Address
          ref="refAddress"
          :addressData="data.addressData"
          :isShipAddress="false"
          :isShowSelectCommonAddress="true"
          :isShowPhone="true"
        ></Address>
      </div>

      <div>
        <a-row type="flex" justify="center">
          <a-col>
            <a-button
              class="ml-5"
              type="primary"
              :loading="buttonCreatePlanLoading"
              :disabled="isDisabledButtonNextStep"
              @click="handleNextStep"
              >{{ $t("common.next_step") }}</a-button
            >
          </a-col>
        </a-row>
      </div>
    </template>
  </Content>
</template>

<script>
import { reactive, toRefs, onMounted, ref, h, nextTick } from "vue";
import Content from "../../components/Content.vue";
import {
  Row,
  Col,
  Steps,
  Input,
  Modal,
  Select,
  Button,
  Form,
  message,
} from "ant-design-vue";
import { outWarehouseType } from "../../../enum/enum.json";
import { useI18n } from "vue-i18n/index";
import {
  getUserOfInventoryWarehouseInfos,
  checkSupportConsignment,
  getBetweenWarehouse,
  getFbaOrFbmWarehouse,
} from "../../../api/modules/storage/index";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import { useTab } from "../../../hooks/tabs/index";
import Address from "../../components/Address.vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { useStore } from "vuex";
import { getAddressByLanguageV2, getLinkman } from "../../../utils/general";
import {
  setObjectSessionStorage,
  getObjectSessionStorage,
  removeObjectSessionStorage,
} from "../../../utils/store";
import { outPlanKeys } from "../../../consts/index";

export default {
  name: "storage_select_out_way",
  components: {
    Content,
    Address,
    ASteps: Steps,
    AStep: Steps.Step,
    ARow: Row,
    ACol: Col,
    AInput: Input,
    ASelect: Select,
    AButton: Button,
    ASelectOption: Select.Option,
    AFormItem: Form.Item,
    AForm: Form,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });
    const router = useRouter();
    const { delVisitedRoute, visitedRoutes } = useTab();
    const refForm = ref();
    const { getters } = useStore();
    const refAddress = ref();

    const state = reactive({
      outWarehouseType: outWarehouseType,
      buttonCreatePlanLoading: false,
      inventoryWarehouseNoLoading: false,
      inventoryWarehouseNos: [], //库存仓库列表
      targetWarehouseNos: [], //目标仓库列表.
      isDisabledButtonNextStep: false,
      //targetWarehouseNos: [],
    });

    const data = reactive({
      selectedInventoryWarehouseId: null,
      inventoryWarehouseAddress: {
        warehouseType: "",
        warehouseNo: "",
        warehouseId: "", //仓库id
      }, //库存仓库地址(包含仓库类型,id,地址信息))

      selectedOutWarehouseType: null,
      selectTargetWarehouseNo: null, //目标仓库代码
      targetAddress: {
        warehouseType: "",
        warehouseNo: "",
        id: "", //仓库id
      },

      addressData: {
        inputAddressAlias: "",
        selectedCommonAddress: null,
        commonAddressName: "", //发自
        selectedCountrys: null,
        selectedProvinces: null,
        selectedCity: null,
        selectedCounty: null,
        detailedAddress: "",
        postCode: "",
      },
    });

    const _funcNextStep = () => {
      if (data.selectedOutWarehouseType == outWarehouseType.otherAddress) {
        let addressInfo = refAddress.value.getAddressInfo();
        Object.assign(data.addressData, addressInfo);
      }
      setObjectSessionStorage(outPlanKeys.createOutPlanData, data);
      delVisitedRoute(router.currentRoute.value);
      router.push({ path: "/storage/addboxinfo" });
    };

    const handleNextStep = async () => {
      refForm.value
        .validate()
        .then(() => {
          if (data.selectedOutWarehouseType == outWarehouseType.otherAddress) {
            return refAddress.value.refForm.validate().then(() => {
              _funcNextStep();
            });
          }
          _funcNextStep();
        })
        .catch((res) => {
          //console.log(res);
        });
    };

    const getAddress = (address) => {
      return getAddressByLanguageV2(address, getters.language);
    };

    const _funcselectData = () => {
      if (
        data.selectedOutWarehouseType ==
        outWarehouseType.betweenWarehouseAllocate
      ) {
        let pra = {
          warehouseId: data.selectedInventoryWarehouseId,
          warehouseType: "1",
        };
        getBetweenWarehouse(pra).then((res) => {
          if (res.result.length > 0) {
            let targetWarehouses = res.result.filter(
              (x) => x.id != data.selectedInventoryWarehouseId
            );
            state.targetWarehouseNos = targetWarehouses;
          }
        });
      } else if (
        data.selectedOutWarehouseType ==
        outWarehouseType.replaceSendWarehouseEntry
      ) {
        if (!data.selectedInventoryWarehouseId) {
          nextTick(() => {
            data.selectedOutWarehouseType = null;
          });
          message.error(
            vueI18n.t("logistics.before_please_select_inventory_warehouse"),
            3
          );
          return false;
        }
        let pra = {
          warehouseId: data.selectedInventoryWarehouseId,
          warehouseType: "2",
        };
        checkSupportConsignment(pra).then((res) => {
          if (res.result) {
            data.targetAddress = res.result;
          } else {
            message.error(
              vueI18n.t("warehouse.select_warehouse_nonsupport_consignment")
            );
            nextTick(() => {
              data.selectedOutWarehouseType = null;
            });
          }
        });
      } else if (
        data.selectedOutWarehouseType == outWarehouseType.amazonFba ||
        data.selectedOutWarehouseType == outWarehouseType.mercadolibre
      ) {
        let platform =
          data.selectedOutWarehouseType == outWarehouseType.amazonFba ? 1 : 2;
        getFbaOrFbmWarehouse({ platform: platform }).then((res) => {
          if (res.result.length > 0) {
            state.targetWarehouseNos = (res.result || []).map((x) => {
              let address = [x.addressLine1, x.addressLine2]
                .filter(Boolean)
                .join(", ");
              return Object.assign({ address }, x);
            });
          }
        });
      } else if (
        data.selectedOutWarehouseType == outWarehouseType.destruction
      ) {
        //销毁
        
      }
    };

    const handelChangeSelectOutType = () => {
      data.selectTargetWarehouseNo = null;
      data.targetAddress = null;
      _funcselectData();
    };

    const handleShowConfim = () => {
      Modal.confirm({
        title: vueI18n.t("warehouse.cannot_create_out_plan"),
        icon: h(ExclamationCircleOutlined),
        content: vueI18n.t("warehouse.we_no_have_your_goods_in_the_warehouse"),
        centered: true,
        mask: true,
        maskClosable: false,
        keyboard: false,
        okText: vueI18n.t("common.confirm"),
        //cancelText: "取消",
        cancelButtonProps: { style: { display: "none" } },
        onOk() {
          delVisitedRoute(router.currentRoute.value);
          router.push({ path: "/storage/outplan" });
        },
      });
    };

    const handleWarehouseInfos = async () => {
      state.inventoryWarehouseNoLoading = true;
      let res = await getUserOfInventoryWarehouseInfos();
      state.inventoryWarehouseNoLoading = false;
      if (res.result.length > 0) {
        state.inventoryWarehouseNos = res.result;
      } else {
        handleShowConfim();
      }
    };

    const handelChangeSelectInventoryWarehouse = () => {
      data.inventoryWarehouseAddress = state.inventoryWarehouseNos.find(
        (x) => x.warehouseId == data.selectedInventoryWarehouseId
      );
    };

    const handelChangeSelectWarehouseCode = () => {
      data.targetAddress = state.targetWarehouseNos.find(
        (x) => x.id == data.selectTargetWarehouseNo
      );
    };

    const getCacheData = () => {
      let cacheData = getObjectSessionStorage(outPlanKeys.createOutPlanData);
      if (cacheData) {
        Object.assign(data, cacheData);

        if (data.selectedOutWarehouseType) {
          _funcselectData();
        }
      }
    };

    getCacheData();
    onMounted(async () => {
      handleWarehouseInfos();
    });

    onBeforeRouteLeave((to, from, next) => {
      const cacheRoutes = ["/storage/selectoutWay", "/storage/addboxinfo"];
      if (!cacheRoutes.includes(to.path)) {
        let tabsHasOrderRoute =
          visitedRoutes.value.findIndex((x) => cacheRoutes.includes(x.path)) >
          -1;
        if (!tabsHasOrderRoute) {
          removeObjectSessionStorage(outPlanKeys.createOutPlanData);
        }
      }
      next();
    });

    const rules = {
      selectedInventoryWarehouseId: [
        {
          required: true,
          //trigger: ['change', 'blur'],
          message: () => {
            return vueI18n.t("common.p0_is_required", [
              vueI18n.t("warehouse.inventory_affiliation_warehouse"),
            ]);
          },
        },
      ],
      selectedOutWarehouseType: [
        {
          required: true,
          type: "integer",
          //trigger: ['change', 'blur'],
          message: () => {
            return vueI18n.t("common.p0_is_required", [
              vueI18n.t("warehouse.out_warehouse_type"),
            ]);
          },
        },
      ],
      selectTargetWarehouseNo: [
        {
          required: true,
          // trigger: ['change', 'blur'],
          message: () => {
            return vueI18n.t("common.p0_is_required", [
              vueI18n.t("warehouse.warehouse_code"),
            ]);
          },
        },
      ],
    };
    return {
      rules,
      refForm,
      refAddress,
      ...toRefs(state),
      data,
      getAddress,
      getLinkman,
      handelChangeSelectOutType,
      handleNextStep,
      handelChangeSelectInventoryWarehouse,
      handelChangeSelectWarehouseCode,
    };
  },
};
</script>

<style lang="less" scoped>
@media (max-width: 450px) {
  .input-w {
    width: 220px;
  }
}
@media (min-width: 451px) {
  .input-w {
    width: 350px;
  }
}
</style>
